<template>
  <div>
    <div
      v-if="leaderboard.results.length === 0 && gameLive === true"
      class="text-nColorLBLightGray text-center mt-2"
    >
      <p>No one has played as yet.</p>
      <p>Be the first to play!</p>
    </div>
    <div
      v-else-if="leaderboard.results.length === 0 && gameLive === false"
      class="text-nColorLBLightGray text-center mt-2"
    >
      <p>No one has played.</p>
    </div>
    <div v-else>
      <div v-for="(result, index) in leaderboard.results" :key="result">
        <div
          :class="{
            'border border-nColorYellow': result.is_customer,
            'border border-black': !result.is_customer,
          }"
          class="flex justify-between items-center gap-2 text-white rounded-xl p-1"
          @click="toggleDetails(result, leaderboard.customer_position)"
          v-if="!showDetails || !result.is_customer"
        >
          <div class="w-10 text-center">
            <img
              :src="getPositionImage(index + 1)"
              :alt="'Position' + index + 1"
              width="24"
              height="27"
              v-if="index < 3"
            />
            <div
              v-else
              class="text-nColorGreen font-bold font-InterSemiBold text-base"
            >
              <p v-if="!result.is_customer">{{ index + 1 }}</p>
              <p v-else>{{ leaderboard.customer_position }}</p>
            </div>
          </div>
          <div class="w-3/6">
            <p class="font-InterSemiBold text-base">{{ result.player }}</p>
          </div>
          <div class="w-3/6">
            <div class="bg-lColorBGGray p-0.5 rounded-xl">
              <div class="flex gap-2">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                    alt="Score"
                    width="20"
                    height="19"
                  />
                </div>
                <p class="font-InterSemiBold text-sm">
                  {{ result.highest_score.score }}
                </p>
              </div>
              <div class="flex gap-2 mt-1" v-if="result.highest_score.time">
                <div class="pl-1">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                    alt="Time Taken"
                    width="15"
                    height="14"
                  />
                </div>
                <p class="font-InterSemiBold text-sm">
                  {{ timeTaken(result.highest_score.time) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="mx-2 border-nColorDarkGray" v-if="!result.is_customer" />
        <div
          v-if="showDetails && result.is_customer"
          class="bg-black p-2 rounded-xl text-white border border-nColorYellow"
        >
          <div class="flex justify-between items-center gap-2 pt-0.5">
            <div class="w-10 text-center">
              <img
                :src="getPositionImage(this.customerPosition)"
                :alt="'Position' + this.customerPosition"
                width="32"
                height="36"
                v-if="index < 3"
              />
              <p
                v-else
                class="text-nColorGreen font-bold font-InterSemiBold text-xl"
              >
                {{ this.customerPosition }}
              </p>
            </div>
            <div class="w-4/6">
              <p class="font-InterSemiBold text-lg">
                {{ customerResult.player }}
              </p>
            </div>
            <div class="w-2/6">
              <button @click.stop="closeDetails()" class="float-right">
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/close.webp"
                  alt="Close"
                  width="30"
                  height="30"
                />
              </button>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 p-2">
            <div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl border-4 border-lColorBGGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  Current rank
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/rank.webp"
                      alt="Rank"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.customer_position }}
                  </p>
                </div>
              </div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl mt-2 border-4 border-lColorBGGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  Last Game
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                      alt="Rank"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.customer_results.last.score }}
                  </p>
                </div>
                <div
                  class="flex items-center gap-2 px-1"
                  v-if="leaderboard.customer_results.last.time"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                      alt="Time Taken"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ timeTaken(leaderboard.customer_results.last.time) }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl border-4 border-lColorBGGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  No. of attempts
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/attempts.webp"
                      alt="Attempts"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.customer_results.attempts }}
                  </p>
                </div>
              </div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl mt-2 border-4 border-nColorLabelGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  Best Game
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                      alt="Rank"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.customer_results.highest.score }}
                  </p>
                </div>
                <div
                  class="flex items-center gap-2 px-1"
                  v-if="leaderboard.customer_results.highest.time"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                      alt="Time Taken"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ timeTaken(leaderboard.customer_results.highest.time) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leaderboard: {
      type: Object,
      required: true,
    },
    gameLive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      customerResult: null,
      customerPosition: null,
      showDetails: false,
    };
  },
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    getPositionImage(index) {
      return `https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/position${index}.webp`;
    },
    toggleDetails(result, index) {
      if (result.is_customer) {
        this.customerResult = result;
        this.customerPosition = index;
        this.showDetails = true;
      }
    },
    closeDetails() {
      this.showDetails = false;
    },
  },
};
</script>

<style scoped lang="postcss"></style>
